/*
-------->Variable name<--------

listingCardBoxShadow;
listingCardBackgroundColor;
listingCardImageBorderRadius;
listingCardBorderRadius;
listingCardTitleColor;
listingCardPriceColor;
listingCardTitleTextSize;
listingCardPriceTextSize;
listingCardPerRow
listingCardHoverBoxShadow
listingCardHoverBackgroundColor

listingCardAccountImageHeight
listingCardAccountImageWidth
listingCardAccountNameTextSize
listingCardAccountNameColor
listingCardAccountNameTextFontWeight
listingCardAccountNameTextLineHeight
listingCardFollowerNameTextSize
listingCardFollowerNameColor
listingCardFollowerNameTextFontWeight
listingCardFollowerNameTextLineHeight
listingCardFlexDirection
listingCardFlexWrap
listingCardJustifyContent
listingCardAlignItems
listingCardAlignContents
listingCardRowGap
listingCardColumnGap

listingCardMarginRight
listingCardMarginLeft
listingCardMarginTop
listingCardMarginBottom
listingCardPaddingLeft
listingCardPaddingRight
listingCardPaddingTop
listingCardPaddingBottom

listingCardImageHeight
listingCardImageWidth
--------> 👆👆👆 Variable name 👆👆👆 .++..<--------


*/

import { listing_card_default_style } from '../Styles_dynamic_values';

export const listing_card_config_setup = ({ is_draft, all_configs }) => {
  let root = document.documentElement;

  const listing_card_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.listing_card ?? {}
    : all_configs?.styles?.listing_card ?? {};
  const dynamic_listing_card_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.dynamic_listing_card ?? {}
    : all_configs?.styles?.dynamic_listing_card ?? {};
  const card_type = listing_card_styles?.card_type?.type;
  const card_min_width =
    card_type === 'custom_card'
      ? dynamic_listing_card_styles?.styles?.list_styles?.min_width
      : listing_card_styles?.card?.min_width;
  const card_per_row =
    card_type === 'custom_card'
      ? dynamic_listing_card_styles?.styles?.list_styles?.gridTemplateColumns
      : listing_card_styles?.card?.max_items_per_row;

  // This just for a single theme , we should work on it
  root.style.setProperty(
    `--listing_card_height`,
    listing_card_styles?.height
      ? listing_card_styles?.height
      : listing_card_default_style(all_configs?.general?.theme)
  );
  root.style.setProperty(
    `--listing_card_width`,
    listing_card_styles?.width
      ? listing_card_styles?.width
      : listing_card_default_style(all_configs?.general?.theme)
  );

  //Card

  root.style.setProperty(
    '--listingCardBoxShadow',
    listing_card_styles?.card?.box_shadow ?? ''
  );

  root.style.setProperty(
    '--listingCardBackgroundColor',
    listing_card_styles?.card?.background_color ?? '#FEFEFE'
  );

  root.style.setProperty(
    '--listingCardBorderRadius',
    listing_card_styles?.card?.curve ?? '8px'
  );
  root.style.setProperty('--listingCardMinWidth', card_min_width ?? '200px');
  root.style.setProperty('--listingCardPerRow', card_per_row ?? '7');

  root.style.setProperty(
    '--listingCardFlexDirection',
    listing_card_styles?.card?.flex_direction ?? 'row'
  );
  root.style.setProperty(
    '--listingCardFlexWrap',
    listing_card_styles?.card?.flex_wrap ?? 'no-wrap'
  );
  root.style.setProperty(
    '--listingCardJustifyContent',
    listing_card_styles?.card?.justify_content ?? ''
  );
  root.style.setProperty(
    '--listingCardAlignItems',
    listing_card_styles?.card?.align_items ?? ''
  );
  root.style.setProperty(
    '--listingCardAlignContents',
    listing_card_styles?.card?.align_content ?? ''
  );
  root.style.setProperty(
    '--listingCardRowGap',
    listing_card_styles?.card?.row_gap ?? ''
  );
  root.style.setProperty(
    '--listingCardColumnGap',
    listing_card_styles?.card?.column_gap ?? ''
  );

  root.style.setProperty(
    '--listingCardMarginRight',
    listing_card_styles?.card?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardMarginLeft',
    listing_card_styles?.card?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardMarginTop',
    listing_card_styles?.card?.margin_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardMarginBottom',
    listing_card_styles?.card?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPaddingLeft',
    listing_card_styles?.card?.padding_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPaddingRight',
    listing_card_styles?.card?.padding_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPaddingTop',
    listing_card_styles?.card?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPaddingBottom',
    listing_card_styles?.card?.padding_bottom ?? '0px'
  );

  // Image

  root.style.setProperty(
    `--listingCardImageMaxWidth`,
    listing_card_styles?.image?.max_width ?? ''
  );
  root.style.setProperty(
    `--listingCardImageMaxHeight`,
    listing_card_styles?.image?.max_height ?? ''
  );
  root.style.setProperty(
    `--listingCardImageMinWidth`,
    listing_card_styles?.image?.min_width ?? ''
  );
  root.style.setProperty(
    `--listingCardImageMinHeight`,
    listing_card_styles?.image?.min_height ?? ''
  );

  root.style.setProperty(
    `--listingCardImageHeight`,
    listing_card_styles?.image?.height
      ? listing_card_styles?.image?.height
      : '100%'
  );

  root.style.setProperty(
    `--listingCardImageWidth`,
    listing_card_styles?.image?.width
      ? listing_card_styles?.image?.width
      : '100%'
  );
  root.style.setProperty(
    '--listingCardImageBorderRadius',
    listing_card_styles?.image?.curve
      ? listing_card_styles?.image?.curve
      : listing_card_styles?.image?.border_radius
      ? listing_card_styles?.image?.border_radius
      : listing_card_styles?.card?.image_curve ?? '0px'
  );

  //
  root.style.setProperty(
    '--listingCardImageObjectFit',
    listing_card_styles?.image?.object_fit ?? 'cover'
  );
  root.style.setProperty(
    '--listingCardImageObjectPosition',
    listing_card_styles?.image?.object_position ?? 'left top'
  );

  //
  root.style.setProperty(
    '--listingCardImageTopLeftBorderRadius',
    listing_card_styles?.image?.top_left_border_radius ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageTopRightBorderRadius',
    listing_card_styles?.image?.top_right_border_radius ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageBottomLeftBorderRadius',
    listing_card_styles?.image?.bottom_left_border_radius ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageBottomRightBorderRadius',
    listing_card_styles?.image?.bottom_right_border_radius ?? '0px'
  );

  root.style.setProperty(
    '--listingCardImageMarginRight',
    listing_card_styles?.image?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageMarginLeft',
    listing_card_styles?.image?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageMarginTop',
    listing_card_styles?.image?.margin_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImageMarginBottom',
    listing_card_styles?.image?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImagePaddingLeft',
    listing_card_styles?.image?.padding_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImagePaddingRight',
    listing_card_styles?.image?.padding_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImagePaddingTop',
    listing_card_styles?.image?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardImagePaddingBottom',
    listing_card_styles?.image?.padding_bottom ?? '0px'
  );

  // Title
  root.style.setProperty(
    '--listingCardTitleColor',
    listing_card_styles?.title?.text_color ??
      all_configs?.onboarding?.app_color_primary ??
      '#004327'
  );

  root.style.setProperty(
    '--listingCardTitleTextSize',
    listing_card_styles?.title?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--listingCardTitleTextFontWeight',
    listing_card_styles?.title?.font_weight ?? '500'
  );
  root.style.setProperty(
    '--listingCardTitleTextLineHeight',
    listing_card_styles?.title?.line_height ?? '16px'
  );
  root.style.setProperty(
    '--listingCardTitleFontFamily',
    listing_card_styles?.title?.font_family
      ? `var(--${listing_card_styles?.title?.font_family})`
      : `var(--primary_font)`
  );

  root.style.setProperty(
    '--listingCardTitleMarginRight',
    listing_card_styles?.title?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardTitleMarginLeft',
    listing_card_styles?.title?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardTitleMarginTop',
    listing_card_styles?.title?.margin_top ?? '8px'
  );
  root.style.setProperty(
    '--listingCardTitleMarginBottom',
    listing_card_styles?.title?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardTitlePaddingLeft',
    listing_card_styles?.title?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--listingCardTitlePaddingRight',
    listing_card_styles?.title?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--listingCardTitlePaddingTop',
    listing_card_styles?.title?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardTitlePaddingBottom',
    listing_card_styles?.title?.padding_bottom ?? '0px'
  );

  // price
  root.style.setProperty(
    '--listingCardPriceColor',
    listing_card_styles?.price?.text_color ??
      'rgb(75 85 99 / var(--tw-text-opacity))'
  );
  root.style.setProperty(
    '--listingCardPriceTextSize',
    listing_card_styles?.price?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--listingCardPriceTextFontWeight',
    listing_card_styles?.price?.font_weight ?? '500'
  );
  root.style.setProperty(
    '--listingCardPriceTextLineHeight',
    listing_card_styles?.price?.line_height ?? '14px'
  );
  root.style.setProperty(
    '--listingCardPriceFontFamily',
    listing_card_styles?.price?.font_family
      ? `var(--${listing_card_styles?.price?.font_family})`
      : `var(--primary_font)`
  );

  root.style.setProperty(
    '--listingCardPriceMarginRight',
    listing_card_styles?.price?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPriceMarginLeft',
    listing_card_styles?.price?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPriceMarginTop',
    listing_card_styles?.price?.margin_top ?? '2px'
  );
  root.style.setProperty(
    '--listingCardPriceMarginBottom',
    listing_card_styles?.price?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPricePaddingLeft',
    listing_card_styles?.price?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--listingCardPricePaddingRight',
    listing_card_styles?.price?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--listingCardPricePaddingTop',
    listing_card_styles?.price?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardPricePaddingBottom',
    listing_card_styles?.price?.padding_bottom ?? '0px'
  );

  // account
  root.style.setProperty(
    `--listingCardAccountImageHeight`,
    listing_card_styles?.account?.image_height
      ? listing_card_styles?.account?.image_height
      : '20px'
  );
  root.style.setProperty(
    `--listingCardAccountImageWidth`,
    listing_card_styles?.account?.image_width
      ? listing_card_styles?.account?.image_width
      : '20px'
  );

  //
  root.style.setProperty(
    '--listingCardAccountNameTextSize',
    listing_card_styles?.account?.name_text_size ?? '10px'
  );
  root.style.setProperty(
    '--listingCardAccountNameColor',
    listing_card_styles?.account?.name_text_color ?? '#4F4F4F'
  );
  root.style.setProperty(
    '--listingCardAccountNameTextFontWeight',
    listing_card_styles?.account?.name_font_weight ?? '500'
  );
  root.style.setProperty(
    '--listingCardAccountNameTextLineHeight',
    listing_card_styles?.account?.name_line_height ?? '12px'
  );

  //
  root.style.setProperty(
    '--listingCardFollowerNameTextSize',
    listing_card_styles?.account?.follower_text_size ?? '10px'
  );
  root.style.setProperty(
    '--listingCardFollowerNameColor',
    listing_card_styles?.account?.follower_text_color ?? '#4f4f4f9a'
  );
  root.style.setProperty(
    '--listingCardFollowerNameTextFontWeight',
    listing_card_styles?.account?.follower_font_weight ?? '500'
  );
  root.style.setProperty(
    '--listingCardFollowerNameTextLineHeight',
    listing_card_styles?.account?.follower_line_height ?? '12px'
  );

  root.style.setProperty(
    '--listingCardAccountMarginRight',
    listing_card_styles?.account?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardAccountMarginLeft',
    listing_card_styles?.account?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardAccountMarginTop',
    listing_card_styles?.account?.margin_top ?? '8px'
  );
  root.style.setProperty(
    '--listingCardAccountMarginBottom',
    listing_card_styles?.account?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardAccountPaddingLeft',
    listing_card_styles?.account?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--listingCardAccountPaddingRight',
    listing_card_styles?.account?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--listingCardAccountPaddingTop',
    listing_card_styles?.account?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardAccountPaddingBottom',
    listing_card_styles?.account?.padding_bottom ?? '0px'
  );

  //

  root.style.setProperty(
    '--listingCardDonationMarginRight',
    listing_card_styles?.donation?.margin_right ?? '0px'
  );
  root.style.setProperty(
    '--listingCardDonationMarginLeft',
    listing_card_styles?.donation?.margin_left ?? '0px'
  );
  root.style.setProperty(
    '--listingCardDonationMarginTop',
    listing_card_styles?.donation?.margin_top ?? '8px'
  );
  root.style.setProperty(
    '--listingCardDonationMarginBottom',
    listing_card_styles?.donation?.margin_bottom ?? '0px'
  );
  root.style.setProperty(
    '--listingCardDonationPaddingLeft',
    listing_card_styles?.donation?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--listingCardDonationPaddingRight',
    listing_card_styles?.donation?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--listingCardDonationPaddingTop',
    listing_card_styles?.donation?.padding_top ?? '0px'
  );
  root.style.setProperty(
    '--listingCardDonationPaddingBottom',
    listing_card_styles?.donation?.padding_bottom ?? '0px'
  );

  // hover
  root.style.setProperty(
    '--listingCardHoverBoxShadow',
    listing_card_styles?.hover?.box_shadow ?? ''
  );

  root.style.setProperty(
    '--listingCardHoverBackgroundColor',
    listing_card_styles?.hover?.background_color ??
      listing_card_styles?.card?.background_color ??
      '#FEFEFE'
  );
  root.style.setProperty(
    '--listingCardImageZoomIN',
    listing_card_styles?.hover?.image_zoom_in ?? false
  );
};
